import React, {Component} from "react"
import BlacklaneCircle from '../components/blacklane-circle'
import HealthtunesCircle from '../components/healthtunes-circle'

class BlacklaneComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
        value: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit() {
    document.location = 'https://www.healthtunes.org/signup?email=' + this.state.value + '@blacklane.com'
  }

  render() {
      console.log(this.state.value)

    return (
      <>
        <header>
          <nav>
            <h3>HealthTunes</h3>

            <ul>
              <li><a href="https://www.healthtunes.org/login">Log In</a></li>
              <li><a href="https://www.healthtunes.org/signup" className="button">Sign Up</a></li>
            </ul>
          </nav>

          <div className="partnership">
            <BlacklaneCircle />
            <HealthtunesCircle />
          </div>
        </header>

        <div className="container">
          <h2>Hello <strong>Blacklane</strong>, meet <strong>HealthTunes</strong></h2>
          <h1>Here to deliver stress & anxiety relief.</h1>

          <p>Blacklane and HealthTunes teamed up to provide you with unique music playlists designed to decrease your anxiety while you listen. The coronavirus pandemic has both directly and indirectly affected the mental health crisis around the globe and enhanced the need for non-invasive relaxation and calming modalities.</p>

          <form>
            <label htmlFor="email">
              <input type="text" placeholder="your.name" name="email" value={this.state.value} onChange={this.handleInputChange} />
              <span onClick={this.handleSubmit}>@blacklane.com</span>
            </label>
          </form>
        </div>
      </>
    )
  }
}

export default BlacklaneComponent
