import React from "react"
import SEO from "../components/seo"
import BlacklaneComponent from "../components/blacklane"

import '../css/reset.css'
import '../css/partner.css'

const Blacklane = () => (
  <>
    <SEO 
      title="Hello Blacklane, meet HealthTunes" 
      description="Blacklane and HealthTunes teamed up to provide you with unique music playlists designed to decrease your anxiety while you listen." 
    />

    <BlacklaneComponent />
  </>
)

export default Blacklane
